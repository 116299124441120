export enum CONTACTS_VIEW_TYPES {
  GRID = "GRID",
  KANBAN = "KANBAN",
}

export enum APP_LANGUAGES {
  VN = "vi-VN",
  US = "en-US",
  ZH = "zh-CN",
  KM = "km-KH",
}

export enum ACCOUNT_ROLE {
  ADMIN = "admin",
}

export enum SMS_CAMPAIGNS_TYPES {
  ADV = "ADV",
  CS = "CS",
}

export enum CUSTOMER_TYPE {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
}

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_URL: "redirect_url",
  CHECKING_PERMISSION: "checking_permission",
  API_KEY: "api_key",
  ACCESS: "access",
  REFRESH: "refresh",
  PREFERRED_ENVIRONMENT: "preferred_environment",
  ENALBE_DEV_MENU: "enable_dev_menu",
  CREDENTIALS: "credentials",
};

export type LOCAL_STORAGE_CREDENTIALS = {
  username: string;
  password: string;
};

export const FORMAT_TYPE_DATE = "DD/MM/YYYY";

export enum DATA_TYPE {
  DATE = "DATE",
  STRING = "STRING",
}

export enum TYPE_MESSAGE {
  ZNS = "ZNS",
  SMS = "SMS",
}

export const GlobalBorderRadiusPx = "12px";

export enum MESSAGE_STATUS {
  success = "SUCCESS",
  fail = "FAIL",
}

export const QUERY_MAXIMUM_RECORDS = {
  page: 1,
  page_size: 9_999_999,
};

export enum ProductSlugEnum {
  product_code = "product_code",
  product_name = "product_name",
  product_price = "product_price",
  product_description = "product_description",
  product_cost = "product_cost",
  product_price_difference = "product_price_difference",
  product_images = "product_images",
  product_payment_cycle = "product_payment_cycle",
  product_category = "product_category",
  changed_by = "changed_by",
  created_by = "created_by",
}

export enum INPUT_TYPE_KEY {
  formulaproperty = "formulaproperty",
  singlelineproperty = "singlelineproperty",
  multilineproperty = "multilineproperty",
  richtextproperty = "richtextproperty",
  integerproperty = "integerproperty",
  floatproperty = "floatproperty",
  dateproperty = "dateproperty",
  singleoptionproperty = "singleoptionproperty",
  multioptionproperty = "multioptionproperty",
  singleuserproperty = "singleuserproperty",
  multiuserproperty = "multiuserproperty",
  associateproperty = "associateproperty",
  phoneproperty = "phoneproperty",
  fileproperty = "fileproperty",
}

export enum TYPE_NOTIFICATION {
  OTHER = "OTHER",
  ASSIGN = "ASSIGN", // phân công
  UPDATE = "UPDATE", // cập nhật
  MESSAGE = "MESSAGE", // tin nhắn
  EXPIRE = "EXPIRE", // hết hạn
  MISS_CALL = "MISS_CALL", // gọi nhỡ
  SYSTEM = "SYSTEM", // hệ thống
  UPLOAD = "UPLOAD", // tải lên
  DOWNLOAD = "DOWNLOAD", // tải xuống
  PAYMENT = "PAYMENT",
}

export const INPUT_FILTER_TYPES = [
  "formulaproperty",
  "singlelineproperty",
  "phoneproperty",
  "integerproperty",
  "richtextproperty",
  "multilineproperty",
];
export const SELECT_FILTER_TYPES = [
  "associateproperty",
  "singleoptionproperty",
  "multioptionproperty",
];

export const NUMBER_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "NOT_EQUAL", label: "Khác với" },
  { value: "GREATER", label: "Lớn hơn với" },
  { value: "GREATER_EQUAL", label: "Lớn hơn hoặc bằng với" },
  { value: "LESS", label: "Nhỏ hơn với với" },
  { value: "LESS_EQUAL", label: "Nhỏ hơn hoặc bằng với" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

export const TEXT_PROPERTY = [
  "singlelineproperty",
  "formulaproperty",
  "phoneproperty",
  "multilineproperty",
  "richtextproperty",
];

export const TEXT_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "NOT_EQUAL", label: "Khác với" },
  { value: "CONTAIN", label: "Có chứa" },
  { value: "NOT_CONTAIN", label: "Không chứa" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

export const TIME_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "GREATER_EQUAL", label: "Sau với" },
  { value: "LESS_EQUAL", label: "Trước với" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

// export const DATA_TYPE: Record<string, string> = {
//   customer_code: "formulaproperty",
//   customer_name: "singlelineproperty",
//   account_manager: "singleuserproperty",
//   support_staff: "multiuserproperty",
//   phone_number: "phoneproperty",
//   email: "singlelineproperty",
//   date_of_birth: "dateproperty",
//   address: "singlelineproperty",
//   city: "singlelineproperty",
//   district: "singlelineproperty",
//   commune: "singlelineproperty",
//   company: "associateproperty",
//   department: "singlelineproperty",
//   position: "singlelineproperty",
//   customer_lifecycle: "singleoptionproperty",
//   label: "singleoptionproperty",
//   lead_source: "singleoptionproperty",
//   first_conversation: "singleoptionproperty",
//   init_time: "dateproperty",
//   first_purchase_time: "dateproperty",
//   latest_purchase_time: "dateproperty",
//   first_interaction_time: "dateproperty",
//   latest_interaction_time: "dateproperty",
//   next_interaction_time: "dateproperty",
//   number_employee_interactions: "integerproperty",
// };

export const DEFAULT_FORM_VALUE = {
  filter_type: "AND",
  filters: [{ property: undefined, operator: undefined }],
};

export const DATE_FORMAT_BY_OPERATOR: Record<string, string> = {
  LESS_EQUAL: "YYYY-MM-DD 23:59:59",
  GREATER_EQUAL: "YYYY-MM-DD 00:00:00",
  LESS: "YYYY-MM-DD 00:00:00",
  GREATER: "YYYY-MM-DD 23:59:59",
};

export const CAMPAIGN_TYPE_NAME: Record<string, string> = {
  autocall: "Callbot AI Auto Call",
  telesale: "Quay số Telesales",
  sms_campaign: "Tin nhắn zalo",
};
